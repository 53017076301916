// Override default variables before the import
$body-bg: #f5fdff;
$text-color: #4373a6;
$layout-breakpoint-small: 720px;


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

#loading {
    background-color: #adeffd;

    img {
        width: 200px;
        max-width: 300px;
    }
}

#loading,
main {
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

main {
    display: flex;

    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    section {
        flex-grow: 1;
        width: 100%;

        &.question {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .content {
                display: flex;
                flex-grow: 1;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .answer {
                    margin: 5px 0;
                    // color: white !important;
                    text-align: left;
                    width: 300px;
                    max-width:60%;
                }
            }

            header,
            footer {
                padding: 20px;

                button {
                    width: 100%
                }
            }
        }
    }
}




@media (min-width: $layout-breakpoint-small) {
    main {
        section {
            width: $layout-breakpoint-small;

        }
    }
}